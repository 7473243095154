body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-carousel .slick-slide {
  text-align: center;
  /* height: 160px;
  line-height: 160px; */
  background: #fafafa;
  overflow: hidden;
  border: #4f4f4f;
}
.ant-carousel .slick-dots {
  margin-top: 15px;
}

.ant-carousel .slick-slide h3 {
  color: #4f4f4f;
}

@media only screen and (max-width: 540px) {
  .ant-divider {
    font-size: 20px !important;
  }
}

/* About us image */
/* @media screen and (max-width: 767px) {
  .feature1-wrapper .feature1-img span {
    width: 350px !important;
  }
} */

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: left;
  border-color: #666;
  background-image: url(/static/media/topBackground1.8c74e6e4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 40%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 800px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 100%;
  height: 117px;
  left: 0px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 80px;
  position: relative;
  text-align: center;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  min-height: 48px;
  font-size: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 100%;
    height: 70px;
    min-height: 20px;
    left: 0;
    font-size: 50px;
    margin-bottom: 1px;
  }
  .banner0 .banner0-content {
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 48px;
    font-size: 13px;
  }
}
.header0 {
  background: #001529;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  right: 0;
}
.header0 .home-page {
  padding: 0 0px;
  padding-top: 10px;
}
.header0-logo {
  position: fixed;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu .ant-menu {
  line-height: 62px;
  height: 100%;
  margin-top: 10px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-menu .ant-menu-item {
  transition-property: box-shadow, color, border-color, border-bottom, background, padding !important;
  border-bottom: 5px solid #1b6fbd00 !important;
  box-sizing: border-box;
}
.header0-menu .ant-menu-item-selected {
  background-color: #1b6fbd00 !important;
  border-bottom: 5px solid #1b6fbd4b !important;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.content1-wrapper {
  background-clip: padding-box;
  background-color: #6384b49a;
  height: 400px;
  background-image: url(/static/media/topBackground1.8c74e6e4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-blend-mode: color;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 425px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 0px;
  height: 100%;
  text-align: center;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 60px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 100% !important;
  }
  .content1-wrapper .content1-img {
    height: 125px;
    padding: 0;
    text-align: center;
    margin-top: 0px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content3-wrapper {
  min-height: 550px;
  overflow: hidden;
}
.content3-wrapper .content3 {
  height: 100%;
  padding: 0px 24px;
}
.content3-wrapper .content3 > .title-wrapper {
  margin: 0 auto 48px;
}
.content3-wrapper .content3-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content3-wrapper .content3-block img {
  width: 100%;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content3-wrapper .content3-block.queue-anim-leaving {
  position: relative !important;
}
.content3-wrapper .content3-block-icon {
  width: 200px;
  height: 200px;
  margin: auto;
}
.content3-wrapper .content3-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 880px;
  }
}
.content0-wrapper {
  min-height: 550px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 200px;
  height: 200px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.feature1-wrapper {
  height: 360px;
}
.feature1-wrapper .feature1 {
  height: 100%;
  padding: 0 24px;
}
.feature1-wrapper .feature1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature1-wrapper .feature1-img span {
  display: block;
  width: 425px;
}
.feature1-wrapper .feature1-img span img {
  display: block;
}
.feature1-wrapper .feature1-text {
  padding: 0 32px;
  height: 100%;
}
.feature1-wrapper .feature1-text .feature1-content,
.feature1-wrapper .feature1-text .feature1-title {
  position: relative !important;
}
.feature1-wrapper .feature1-text .feature1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.feature1-wrapper .feature1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .feature1-wrapper {
    height: 480px;
  }
  .feature1-wrapper .feature1-img {
    height: 140px;
    padding: 0;
    text-align: center;
    margin-top: 0px;
  }
  .feature1-wrapper .feature1-img span {
    display: inline-block;
    width: 250px;
    height: 100px;
    line-height: 200px;
    margin: auto;
  }
  .feature1-wrapper .feature1-text {
    height: auto;
    margin-top: 25px;
    text-align: center;
    padding: 0;
  }
  .feature1-wrapper .feature1-text .feature1-content,
  .feature1-wrapper .feature1-text .feature1-title {
    width: 100%;
    top: auto;
  }
  .feature1-wrapper .feature1-text .feature1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.feature2-wrapper {
  height: 360px;
}
.feature2-wrapper .feature2 {
  height: 100%;
  padding: 0 24px;
}
.feature2-wrapper .feature2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature2-wrapper .feature2-img span {
  display: block;
  width: 425px;
}
.feature2-wrapper .feature2-img span img {
  display: block;
}
.feature2-wrapper .feature2-text {
  margin-top: 100px;
  height: 100%;
}
.feature2-wrapper .feature2-text .feature2-content,
.feature2-wrapper .feature2-text .feature2-title {
  position: relative !important;
}
.feature2-wrapper .feature2-text .feature2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 0px;
}
.feature2-wrapper .feature2-text .feature2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .feature2-wrapper {
    height: 600px;
  }
  .feature2-wrapper .feature2-img {
    height: 140px;
    padding: 0;
    text-align: center;
    margin-top: 12px;
  }
  .feature2-wrapper .feature2-img span {
    display: inline-block;
    width: 250px;
    height: 100px;
    line-height: 200px;
    margin: auto;
  }
  .feature2-wrapper .feature2-text {
    height: auto;
    margin-bottom: 10px;
    text-align: center;
    padding: 0;
  }
  .feature2-wrapper .feature2-text .feature1-content,
  .feature2-wrapper .feature2-text .feature1-title {
    width: 100%;
    top: auto;
  }
  .feature2-wrapper .feature2-text .feature2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.list {
  padding-top: 10px;
  padding-left: 20px;
}
.list li {
  margin-bottom: 20px;
}
.feature3-wrapper {
  height: 360px;
}
.feature3-wrapper .feature3 {
  height: 100%;
  padding: 0 24px;
}
.feature3-wrapper .feature3-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature3-wrapper .feature3-img span {
  display: block;
  width: 425px;
}
.feature3-wrapper .feature3-img span img {
  display: block;
}
.feature3-wrapper .feature3-text {
  padding: 0 32px;
  height: 100%;
}
.feature3-wrapper .feature3-text .feature3-content,
.feature3-wrapper .feature3-text .feature3-title {
  position: relative !important;
}
.feature3-wrapper .feature3-text .feature3-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.feature3-wrapper .feature3-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .feature3-wrapper {
    height: 500px;
  }
  .feature3-wrapper .feature3-img {
    height: 140px;
    padding: 0;
    text-align: center;
    margin-top: 12px;
  }
  .feature3-wrapper .feature3-img span {
    display: inline-block;
    width: 250px;
    height: 100px;
    line-height: 200px;
    margin: auto;
  }
  .feature3-wrapper .feature3-text {
    height: auto;
    margin-bottom: 10px;
    text-align: center;
    padding: 0;
  }
  .feature3-wrapper .feature3-text .feature1-content,
  .feature3-wrapper .feature3-text .feature1-title {
    width: 100%;
    top: auto;
  }
  .feature3-wrapper .feature3-text .feature3-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.feature4-wrapper {
  height: 360px;
}
.feature4-wrapper .feature4 {
  height: 100%;
  padding: 0 24px;
}
.feature4-wrapper .feature4-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature4-wrapper .feature4-img span {
  display: block;
  width: 425px;
}
.feature4-wrapper .feature4-img span img {
  display: block;
}
.feature4-wrapper .feature4-text {
  padding: 0 32px;
  height: 100%;
}
.feature4-wrapper .feature4-text .feature4-content,
.feature4-wrapper .feature4-text .feature4-title {
  position: relative !important;
}
.feature4-wrapper .feature4-text .feature4-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.feature4-wrapper .feature4-text .feature4-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .feature4-wrapper {
    height: 500px;
  }
  .feature4-wrapper .feature4-img {
    height: 140px;
    padding: 0;
    text-align: center;
    margin-top: 12px;
  }
  .feature4-wrapper .feature4-img span {
    display: inline-block;
    width: 250px;
    height: 100px;
    line-height: 200px;
    margin: auto;
  }
  .feature4-wrapper .feature4-text {
    height: auto;
    margin-bottom: 10px;
    text-align: center;
    padding: 0;
  }
  .feature4-wrapper .feature4-text .feature1-content,
  .feature4-wrapper .feature4-text .feature1-title {
    width: 100%;
    top: auto;
  }
  .feature4-wrapper .feature4-text .feature4-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content2-wrapper {
  background-clip: padding-box;
  background-color: #ffffff;
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 425px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 100px;
}
.content2-wrapper .content2-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 400px;
  }
  .content2-wrapper .content2-img {
    height: 85px;
    padding: 0;
    text-align: center;
    margin-top: 5px;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 240px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  height: 200px;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 600px;
  }
}
.content10-wrapper {
  height: 480px;
  background: url(/mapcontact3.png) no-repeat 50%;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content10-wrapper .icon-wrapper {
  text-align: center;
  position: relative;
  cursor: pointer;
}
.content10-wrapper .icon-wrapper img {
  display: block;
}
.content10-wrapper .icon {
  position: relative;
  z-index: 1;
  -webkit-animation: BeatAnim 2s ease-in-out infinite;
          animation: BeatAnim 2s ease-in-out infinite;
}
.content10-wrapper .icon-shadow {
  display: inline-block;
  position: relative;
  top: -12px;
  z-index: 0;
  -webkit-animation: ScaleAnim 2s ease-in-out infinite;
          animation: ScaleAnim 2s ease-in-out infinite;
  -webkit-transform-origin: 50%;
          transform-origin: 50%;
}
.content10-wrapper .map-tip {
  position: absolute;
  width: 330px;
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(13, 26, 38, 0.12);
  left: 50%;
  top: 50%;
  margin-left: 30px;
  margin-top: -60px;
  font-size: 14px;
  z-index: 10;
  -webkit-transform-origin: 0 50%;
          transform-origin: 0 50%;
  text-align: left;
}
.content10-wrapper .map-tip h2 {
  font-size: 16px;
  color: #0d1a26;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .content10-wrapper {
    padding-bottom: 0;
  }
  .map-tip {
    left: 0% !important;
    top: 25% !important;
  }
}
@-webkit-keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@keyframes BeatAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  15% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  30% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  40% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
  }
}
@-webkit-keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes ScaleAnim {
  0%,
  25%,
  35%,
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  15% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  30% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
.content {
  min-height: calc(100vh - 80px);
}
.footer2-wrapper {
  background-color: #0d1a26;
  height: 80px;
  overflow: hidden;
}
.footer2-wrapper .footer2 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
.footer2-wrapper .copyright {
  float: right;
}
.footer2-wrapper .copyright > * {
  display: inline-block;
}
.footer2-wrapper .copyright-logo {
  width: 16px;
  margin-right: 8px;
}
.footer2-wrapper .copyright-logo img {
  width: 10px;
}
.footer2-wrapper .copyright-line {
  padding: 0 12px;
  margin: 0 12px;
}
.footer2-wrapper .links {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}
.footer2-wrapper .links a {
  height: 21px;
  display: inline-block;
  margin-right: 32px;
  margin-bottom: 32px;
}
.footer2-wrapper .links a img {
  display: block;
}
@media screen and (max-width: 767px) {
  .footer2-wrapper .footer2 {
    font-size: 12px;
  }
  .footer2-wrapper .footer2.home-page {
    padding: 0;
  }
  .footer2-wrapper .footer2 > div {
    width: 90%;
    margin: auto;
  }
}

.logoContainer {
  display: none;
}
.logoContainer img {
  width: 25%;
}
@media screen and (min-width: 768px) {
  .logoContainer img {
    width: 200px;
  }
}
@media screen and (min-width: 992px) {
  .logoContainer img {
    width: 300px;
  }
}
.reference {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
}
.reference p {
  margin-top: 20px;
  line-height: 1.5;
  font-style: italic;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 997px;
}
.reference p.navnelinje {
  font-style: normal;
  font-weight: 500;
}
.reference img {
  height: 170px;
  max-width: 100%;
  object-fit: contain;
}

.projectContainer {
  display: grid;
  grid-template-rows: masonry;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 768px) {
  .projectContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1000px) {
  .projectContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1280px) {
  .projectContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .projectContainer .ant-card {
    margin: 20px;
  }
}
@media (min-width: 1280px) {
}


.ant-carousel .slick-dots {
  height: 5px;
}
.ant-carousel .slick-dots li {
  background-color: #bbbbbb;
}
.ant-carousel .slick-dots li button {
  height: 5px;
  width: calc(50px * 1);
}
.ant-carousel .slick-dots li.slick-active {
  background-color: #71a5cf;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button {
  height: 5px;
  background-color: #71a5cf;
  width: calc(50px * 1.5);
}
#bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#bodyContainer .bodytext {
  max-width: 600px;
}


.formContainer {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: blue;
  overflow: visible;
}
.downArrow {
  position: fixed;
  bottom: 45%;
  left: 50%;
}
.bounce {
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
/* Demo Purpose Only*/
.demo {
  font-family: 'Raleway', sans-serif;
  color: #fff;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  text-align: center;
}
.demo a {
  font-family: 'Raleway', sans-serif;
  color: #2ecc71;
}


