body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-carousel .slick-slide {
  text-align: center;
  /* height: 160px;
  line-height: 160px; */
  background: #fafafa;
  overflow: hidden;
  border: #4f4f4f;
}
.ant-carousel .slick-dots {
  margin-top: 15px;
}

.ant-carousel .slick-slide h3 {
  color: #4f4f4f;
}

@media only screen and (max-width: 540px) {
  .ant-divider {
    font-size: 20px !important;
  }
}

/* About us image */
/* @media screen and (max-width: 767px) {
  .feature1-wrapper .feature1-img span {
    width: 350px !important;
  }
} */
